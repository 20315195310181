import { Autocomplete, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { useTheme } from '@mui/material/styles';
import Iconify from '#/components/shared/ui/Iconify';
import useAddressAutocomplete from '#/hooks/useAddressAutocomplete';
import useLocales from '#/hooks/useLocales';

type Props = {
  fieldNames: any;
  streetError: boolean;
  name?: string;
};

const AutoAddressPicker = ({
  fieldNames,
  streetError,
  name = 'street',
}: Props) => {
  const theme = useTheme();
  const { translate } = useLocales();
  const { control, setValue } = useFormContext();

  const {
    ready,
    suggestions: { status, data },
    setValue: setAutocompleteValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: 'ch',
      },
    },
  });

  const { handleSelect } = useAddressAutocomplete(
    setValue,
    setAutocompleteValue,
    fieldNames
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Autocomplete
          freeSolo
          value={field.value}
          disableClearable
          onChange={async (_, newValue) => {
            if (typeof newValue === 'string') {
              await handleSelect(newValue);
            } else if (newValue && newValue.inputValue) {
              await handleSelect(newValue.inputValue);
            }
          }}
          options={
            status === 'OK' && ready
              ? data.map((suggestion) => suggestion.description)
              : []
          }
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={
                streetError ? (
                  <Typography variant="caption" color="error">
                    {String(translate('validations.adress.street'))}
                  </Typography>
                ) : (
                  ''
                )
              }
              error={streetError}
              label={String(translate('global.formLabels.street'))}
              onChange={(e) => {
                setAutocompleteValue(e.target.value);
                field.onChange(e);
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme.palette.divider,
                  },
                },
              }}
              InputProps={{
                ...params.InputProps,
                type: 'search',
                sx: {
                  '& .MuiAutocomplete-input': {
                    pl: 0,
                  },
                },
                endAdornment: !ready && (
                  <Iconify icon="line-md:loading-twotone-loop" />
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export default AutoAddressPicker;
