import { useFormContext, Controller } from 'react-hook-form';
import { FormHelperText, TextFieldProps, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import useLocales from '#/hooks/useLocales';

type IProps = {
  name: string;
  label?: string;
  disableFuture?: boolean;
};

type Props = IProps & TextFieldProps;

export default function RHFDatePicker({ name, label, disableFuture }: Props) {
  const { control } = useFormContext();

  const { translate } = useLocales();
  const theme = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <DatePicker
            disableFuture={disableFuture}
            label={label || 'Date'}
            format="dd.MM.yyyy"
            value={field.value ? new Date(field.value) : null}
            onChange={(newValue) => {
              field.onChange(newValue);
            }}
            sx={{
              width: '100%',
              '& .MuiInputLabel-root': {
                color: error ? 'red' : theme.palette.text.secondary,
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: error ? 'red' : theme.palette.divider,
                },
              },
            }}
          />
          {error && (
            <FormHelperText error={!!error}>
              {String(translate(error?.message))}
            </FormHelperText>
          )}
        </>
      )}
    />
  );
}
